<template>
  <div data-component-name="AppTextarea">
    <textarea
      :id
      v-model="modalValue"
      :placeholder
      :class="{ error }"
      :name
    />

    <span v-if="error" class="error">
      {{ error }}
    </span>
  </div>
</template>

<script setup lang="ts">
const modalValue = defineModel<string>();
const id = useId();

defineProps<{
  placeholder?: string
  error?: string
  name?: string,
}>();
</script>

<style scoped lang="scss">
@import "$/mixins/typography";
@import "$/mixins/common";

@import "$/functions/token";

[data-component-name="AppTextarea"] {
  width: 100%;

  textarea {
    border: unset;
    resize: none;
    width: inherit;

    padding: 1rem;
    background-color: token('surf-cont-secondary');

    transition: all 0.15s ease;

    @include body-3;
    color: token('text-primary');
    caret-color: token('outline-action');

    &::placeholder {
      color: token('text-secondary');
    }

    &:hover,
    &:focus {
      background-color: rgba(token('accent-hover'), 16%);
    }

    &:focus {
      box-shadow: inset 0 0 0 1px token('accent-hover');
    }

    &.error {
      box-shadow: inset 0 0 0 1px token('error');
    }
  }

  span.error {
    @include caption;
    color: token('error');
    margin-top: 0.25rem;
    display: block;
  }
}
</style>
