import Cookie from '~/components/services/Cookie';

export const submittedCookieValue = ((name: string): string | null => {
  if (process.client) {
    const submittedParams = Cookie.get('submitted_params');

    if (!submittedParams) {
      return null;
    }

    const params = JSON.parse(submittedParams);

    if (params && params[name]) {
      return params[name];
    }
  }

  return null;
})
