<template>
  <svg
    viewBox="0 0 20 20"
    fill="#fff"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        d="M 16 4 Z M 5.332 10 L 8.6641 13.333 L 14.6641 7.333 L 13.7266 6.3867 L 8.6641 11.4463 L 6.2734 9.0596 L 5.332 10 Z"
        fill="inherit"
      />
    </g>
  </svg>
</template>
